import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";
import { toast } from "react-toastify";
import InfoModel from "../../shared/simpleModel/InfoModel";

const DieticianDietIntervention = ({ order }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dietIntervention, setDietIntervention] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [communication, setCommunication] = useState();
  const [answers, setAnswers] = useState([]);
  const [answererrors, setAnswerErrors] = useState({});
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);
  const [displayFileType, setDisplayFileType] = useState("");

  const handleInputChange = (e) => {
    setDietIntervention(e.target.value);
    if (e.target.value) setError(""); // Clear error when user types
  };

  const closeReportModal = () => {
    setModalOpen(false);
  };

  const validateAnswers = () => {
    let isValid = true;
    let newErrors = {};

    communication.communicationView.forEach((item) => {
      const answer = answers.find((ans) => ans.id === item.id);
      if (!answer || answer.answer.trim() === "") {
        newErrors[item.id] = "This field is required";
        isValid = false;
      }
    });

    setAnswerErrors(newErrors);
    return isValid;
  };

  const handleChange = (id, value) => {
    setAnswerErrors((prevErrors) => ({
      ...prevErrors,
      [id]: value.trim() === "" ? "This field is required" : null, // Reset the error if the field is empty, or remove it
    }));
    setAnswers((prevAnswers) => {
      // Check if the answer with the same id already exists
      const existingAnswerIndex = prevAnswers.findIndex((ans) => ans.id === id);

      if (existingAnswerIndex !== -1) {
        // Update the existing answer
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answer = value;
        return updatedAnswers;
      } else {
        // Add a new answer object
        return [...prevAnswers, { id, answer: value }];
      }
    });
  };

  const handleAnswerSubmit = () => {
    if (validateAnswers()) {
      // if (validateForm()) {
      setIsLoading(true);
      console.log("answers", answers); // This will print the array of answers with their ids
      const userDetails = sessionStorage.getItem("userDetails");
      const parsedData = JSON.parse(userDetails);
      const userType = parsedData.userType;
      AxiosInstance.post(
        `dietitian/sendMassage/${order.orderId}/${userType}`,
        answers
      )
        .then((response) => {
          setIsLoading(false);
          setIsSubmitted(true);
          setAnswerSubmitted(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err: ", err);
        });
      // }

      // Call your API or save the data here
    }
  };

  const handleSubmit = async () => {
    // Validate input
    if (!dietIntervention.trim()) {
      setError("This field is required.");
      return;
    }

    const sendConsultData = {
      orderId: order.orderId,
      review: dietIntervention,
    };

    // Post review
    setIsLoading(true);
    AxiosInstance.post("dietitian/sendReviewForDiet", sendConsultData)
      .then((response) => {
        setIsLoading(false);
        setIsSubmitted(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
      });
    console.log("order send", sendConsultData);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    AxiosInstance.post(
      `dietitian/upload-diet-file?orderId=${order.orderId}`,
      formData
    )
      .then((response) => {
        setIsSubmitted(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err: ", err);
      });
  };

  useEffect(() => {
    if (order && Object.keys(order).length > 0) {
      AxiosInstance.get(`dietitian/getCommunication/${order.orderId}`)
        .then((response) => {
          if (response != null) {
            setCommunication(response);
            // setDietIntervention(response.doctorReview.review);
            const prepopulatedAnswers = response.communicationView.map(
              (item) => ({
                id: item.id,
                answer: item.answer || "", // If there's no answer, set it as an empty string
              })
            );
            setAnswers(prepopulatedAnswers);
          } else {
            setCommunication(null);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, []);
  const downloadDietPlan = () => {
    console.log("url: ", communication?.dietReviewDto?.dietFile);
    const url = communication?.dietReviewDto?.dietFile;
    if (url === null) {
      return;
    }

    const downloadApi = "dietitian/downloadDietFile";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentType = response.headers.get("Content-Type");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts

              if (
                contentType === "application/pdf" ||
                contentType === "application/msword" ||
                contentType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // PDF/Word display in iframe
                setDisplayFileType("iframe");
              } else if (
                contentType ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                contentType === "application/vnd.ms-excel"
              ) {
                // Excel file handling
                setDisplayFileType("download");
              } else {
                // Handle other types as download
                setDisplayFileType("download");
              }
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };
  console.log("order idie", order, communication);
  return (
    <>
      {isSubmitted ? (
        answerSubmitted ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 2,
            }}
          >
            <div>
              <div>
                <MdCheckCircle size={50} color="green" />
              </div>
              <Typography variant="h6" sx={{ color: "green" }}>
                You have successfully submitted your response to the questions
                raised by the client for this paticular consultation.
              </Typography>
              {/* <div style={{ fontSize: "16px" }}>
                This completes the order placed by the patient.
              </div>
              <div style={{ fontSize: "16px" }}>Thanks for your Review!</div> */}
            </div>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 2,
            }}
          >
            <div>
              <div>
                <MdCheckCircle size={50} color="green" />
              </div>
              <Typography variant="h6" sx={{ color: "green" }}>
                You have successfully submitted your consultation to the
                patient.
              </Typography>
              <div style={{ fontSize: "16px" }}>
                Please note that the Patient may send you queries, if they have
                any questions after going through your comments or need any
                further clarifications.
              </div>
            </div>
          </Box>
        )
      ) : communication?.communicationView ? (
        <div>
          <Typography component="div" variant="h6" sx={{ marginBottom: 1 }}>
            Diet Plan Suggested by You
          </Typography>
          {communication?.dietReviewDto.review ? (
            <TextField
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
              value={communication?.dietReviewDto?.review}
              disabled
              sx={{
                "& .MuiOutlinedInput-root.Mui-disabled": {
                  backgroundColor: "#d3d3d37a", // Full background color for the input
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black", // Ensures the text is black when disabled
                },
              }}
            />
          ) : (
            <div
              style={{
                fontWeight: "bolder",
                fontSize: "20px",
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={downloadDietPlan}
            >
              View Diet Plan
            </div>
          )}

          <Box
            sx={{
              padding: "24px",
              backgroundColor: "#1c1c1c", // Dark background
              borderRadius: "16px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
              border: "1px solid #333",
              marginBottom: "20px",
            }}
          >
            {communication?.communicationView?.map((item, index) => (
              <Box key={item.id} sx={{ marginBottom: "24px" }}>
                {/* Question Heading */}
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    color: "#ffa726", // Warm orange for headings
                    fontWeight: "bold",
                  }}
                >
                  {`Question ${index + 1} raised by patient`}
                </Typography>
                {/* Question Text */}
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    color: "#ffffff", // White text for contrast
                    backgroundColor: "#424242", // Dark gray background for the question
                    padding: "12px",
                    borderRadius: "8px",
                    border: "1px solid #555",
                  }}
                >
                  {item.question}
                </Typography>

                {/* Your Response Heading */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    marginTop: "8px",
                    color: "#80cbc4", // Soft teal for response heading
                  }}
                >
                  Your Response
                </Typography>

                {/* Answer TextField */}
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  placeholder="Type your response here..."
                  value={
                    answers.find((ans) => ans.id === item.id)?.answer || ""
                  }
                  onChange={(e) => handleChange(item.id, e.target.value)}
                  error={!!answererrors[item.id]}
                  helperText={
                    answererrors[item.id] ? answererrors[item.id] : ""
                  }
                  InputProps={{
                    style: {
                      color: "#ffffff", // White color for the text when typing
                      fontWeight: "bold", // Bold font weight
                    },
                  }}
                  sx={{
                    backgroundColor: "#333", // Darker background for the answer input
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#666", // Matching dark border
                      },
                      "&:hover fieldset": {
                        borderColor: "#ffa726", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#ffa726", // Border color on focus
                      },
                    },
                  }}
                />
                <Divider sx={{ marginTop: "16px", borderColor: "#444" }} />
              </Box>
            ))}

            {/* Submit Button */}
          </Box>
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleAnswerSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </div>
      ) : (
        <div>
          <Typography variant="h6" className="mb-3" sx={{ fontWeight: "bold" }}>
            Please Type in your nutrition protocol or you can attach protocol in
            a word doc. using the attach file tab
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button variant="contained" component="label" color="primary">
              Attach File
              <Input type="file" hidden onChange={handleFileChange} />
            </Button>
            {selectedFile && (
              <Typography
                variant="body2"
                style={{ marginTop: "10px", color: "gray" }}
              >
                {selectedFile.name}
              </Typography>
            )}
          </div>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
            mt={2}
          >
            <Divider style={{ flexGrow: 1 }} />
            <Typography variant="h6" style={{ padding: "0 10px" }}>
              Or
            </Typography>
            <Divider style={{ flexGrow: 1 }} />
          </Box>
          <TextField
            label="Type Your Diet Intervention"
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            style={{ marginBottom: "20px" }}
            error={!!error}
            helperText={error}
            value={dietIntervention}
            onChange={handleInputChange}
          />
          <Button
            variant="contained"
            color="success"
            fullWidth
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </div>
      )}
      <InfoModel
        open={modalIsOpen}
        setOpen={closeReportModal}
        maxWidth={"md"}
        contentLabel="File Popup"
        haveCloseIcon={true}
      >
        {reportUrl && displayFileType === "iframe" && (
          <iframe
            src={reportUrl}
            title="Report Viewer"
            style={{ width: "100%", height: "600px", border: "none" }}
          />
        )}
        {reportUrl && displayFileType === "download" && (
          <div>
            <p>File cannot be previewed. Click below to download:</p>
            <a href={reportUrl} download>
              Download File
            </a>
          </div>
        )}
      </InfoModel>
    </>
  );
};

export default DieticianDietIntervention;
