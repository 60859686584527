import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Divider,
  Button,
  Modal,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

const StyledCard = styled(Card)(({ bgcolor }) => ({
  backgroundColor: bgcolor,
  color: "#fff",
  borderRadius: "10px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
  },
  display: "flex",
  flexDirection: "column",
  height: "100%", // Ensures cards have equal height
}));

const TitleTypography = styled(Typography)({
  fontSize: "1.5rem",
  fontWeight: "bold",
  textAlign: "center",
  color: "black",
  textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
  padding: "0px 0px 10px 0px",
});

const CorporateHealthPlans = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleModalOpen = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const benefitsData = [
    {
      level: "Gold-Basic",
      benefits: [
        {
          category: "All Bronze Benefits",
          color: "#cd7f32",
          items: [
            "Store and access your medical records on cloud whenever and wherever you need.",
            "Get complete information of all your lifelong hospital visits at your fingertip!",
          ],
        },
        {
          category: "All Silver Benefits",
          color: "slategray",
          items: [
            "Get your lifestyle induced health risk score to understand your health risks.",
            "Understand your real risks for various lifestyle diseases such as heart disease, stroke, Diabetes, Hypertension, PCOD, etc.",
            "Store, track, and monitor your BMI, BP, and Blood sugar to understand predicted future trends and risks.",
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleModalOpen("Silver Benefits")}
            >
              Many other benefits
            </Button>,
          ],
        },
        {
          category: "All Gold Benefits",
          color: "darkgoldenrod",
          items: [
            "Get personalized preventive recommendations for reducing your health risks.",
            "Receive preventive lifestyle modifications counseling from expert dietitians, doctors, physical therapists, and Yoga experts in our network to reduce your health risks.",
            <Button
              variant="text"
              color="secondary"
              onClick={() => handleModalOpen("Gold Benefits")}
            >
              Many other benefits
            </Button>,
          ],
        },
      ],
      color: "#8bc34a61",
    },
    {
      level: "Gold-Advance",
      benefits: [
        "All Benefits in Gold Basic Level",
        "Annually ONE Free ONLINE Pathology Test report Review by a Doctor in our Panel (Rs. 500-1000 value)",
      ],
      color: "#03a9f445",
    },
    {
      level: "Gold-Premium",
      benefits: [
        "All Benefits in Gold Advance",
        "Annually ONE Free consultation with an expert Clinical Dietitian in our Panel (Rs. 1200-1500 VALUE)",
      ],
      color: "#ff572252",
    },
    {
      level: "Gold-Lifestyle Coaching",
      benefits: [
        "All Benefits in Gold Advance",
        "Annually ONE Free consultation with our Lifestyle Coach for overall lifestyle improvement advice (Rs. 2000-3000 value)",
      ],
      color: "#ff149342",
    },
  ];

  const handleModalClose = () => {
    setModalOpen(false);
    setModalContent("");
  };

  return (
    <>
      <Grid
        container
        spacing={4}
        justifyContent="center"
        sx={{ flexWrap: "wrap" }}
      >
        {/* Gold Basic Card */}
        <Grid item xs={12} md={6}>
          <StyledCard bgcolor={benefitsData[0].color}>
            <CardContent sx={{ flexGrow: 1, paddingBottom: "0 !important" }}>
              <TitleTypography>{benefitsData[0].level}</TitleTypography>
              {benefitsData[0].benefits.map((section, idx) => (
                <Box key={idx} sx={{ paddingBottom: "10px" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: section.color }}
                  >
                    {section.category}
                  </Typography>
                  <Box
                    component="ul"
                    sx={{
                      paddingLeft: "20px",
                      listStyleType: "disc",
                      color: "#e0f7fa",
                      marginBottom: "0px",
                    }}
                  >
                    {section.items.map((item, subIdx) => (
                      <Typography
                        variant="body1"
                        component="li"
                        sx={{ color: "black" }}
                        key={subIdx}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ))}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Other Three Cards - Gold Advance, Gold Premium, and Gold Lifestyle Coaching */}
        <Grid item container direction="column" spacing={4} xs={12} md={6}>
          {benefitsData.slice(1).map((benefit, index) => (
            <Grid item key={index}>
              <StyledCard bgcolor={benefit.color}>
                <CardContent
                  sx={{ flexGrow: 1, paddingBottom: "0 !important" }}
                >
                  <TitleTypography>{benefit.level}</TitleTypography>
                  <Box
                    component="ul"
                    sx={{
                      paddingLeft: "20px",
                      listStyleType: "none",
                      marginBottom: "0px",
                    }}
                  >
                    {benefit.benefits.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <Typography
                          variant="body1"
                          component="li"
                          sx={{
                            marginBottom: "8px",
                            textAlign: "center",
                            color: "black",
                          }}
                        >
                          {item}
                        </Typography>
                        {idx < benefit.benefits.length - 1 && (
                          <Box sx={{ textAlign: "center", marginY: "8px" }}>
                            <AddIcon sx={{ fontSize: 30, color: "purple" }} />
                          </Box>
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Modal for Additional Benefits */}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ marginBottom: "16px" }}
          >
            {modalContent}
          </Typography>
          <Typography id="modal-description" variant="body1">
            Here you can add detailed content related to "{modalContent}" for
            the benefits provided in this category.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default CorporateHealthPlans;
