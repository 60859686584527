import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "./Admin.css";
import { toast } from "react-toastify";
import AccountManagerCommentModel from "./AccountManagerCommentModel";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
const ApprovalsInvoice = () => {
  const [LabDetails, setLabDetails] = useState();
  const [invoiceData, setInvoiceData] = useState([]); 
  const [openModal, setOpenModal] = useState(false);
   const [openCommentModal, setOpenCommentModal] = useState(false); // Modal open state
   const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [formState, setFormState] = useState({
    labName: "",
    labId: "",
  });

 const handleOpenCommentModal = (invoice) => {
   setSelectedInvoice(invoice); // Set the selected invoice data
   setOpenCommentModal(true); // Open the modal
 };

 // Close the comment modal
 const handleCloseCommentModal = () => {
   setOpenCommentModal(false); // Close the modal
   setSelectedInvoice(null); // Clear selected invoice data
 };

  
  useEffect(() => {
    AxiosInstance.get("corporate-order/getMedicalLabs")
      .then((response) => {
        if (response != null) {
          setLabDetails(response);
        } else {
          setLabDetails([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);
   const handleCloseModal = () => setOpenModal(false);

   const dummyLabs = [
     { labId: 36, labName: "Lab A" },
     { labId: 37, labName: "Lab B" },
     { labId: 38, labName: "Lab C" },
   ];

   // Dummy invoice data for 3 labs
   const dummyInvoiceData = {
     36: [
       {
         invoiceMonthYear: "Jan 2024",
         totalOrders: 15,
         totalAmount: "$1500",
         invoiceFile: "invoice_A.pdf",
         invoiceDate: "2024-01-15",
         invoiceAmount: "$1500",
         totalConfirmed: "Yes",
         approval: "Approved",
         comments: "N/A",
         accComment: "N/A",
       },
       {
         invoiceMonthYear: "Feb 2024",
         totalOrders: 10,
         totalAmount: "$1000",
         invoiceFile: "invoice_A2.pdf",
         invoiceDate: "2024-02-15",
         invoiceAmount: "$1000",
         totalConfirmed: "No",
         approval: "Pending",
         comments: "N/A",
         accComment: "Pending Review",
       },
     ],
     37: [
       {
         invoiceMonthYear: "March 2024",
         totalOrders: 20,
         totalAmount: "$2000",
         invoiceFile: "invoice_B.pdf",
         invoiceDate: "2024-01-20",
         invoiceAmount: "$2000",
         totalConfirmed: "Yes",
         approval: "Approved",
         comments: "N/A",
         accComment: "N/A",
       },
       {
         invoiceMonthYear: "April 2024",
         totalOrders: 25,
         totalAmount: "$2500",
         invoiceFile: "invoice_B2.pdf",
         invoiceDate: "2024-02-18",
         invoiceAmount: "$2500",
         totalConfirmed: "No",
         approval: "Pending",
         comments: "N/A",
         accComment: "Pending Review",
       },
     ],
     38: [
       {
         invoiceMonthYear: "May 2024",
         totalOrders: 18,
         totalAmount: "$1800",
         invoiceFile: "invoice_C.pdf",
         invoiceDate: "2024-01-25",
         invoiceAmount: "$1800",
         totalConfirmed: "Yes",
         approval: "Approved",
         comments: "N/A",
         accComment: "N/A",
       },
       {
         invoiceMonthYear: "June 2024",
         totalOrders: 22,
         totalAmount: "$2200",
         invoiceFile: "invoice_C2.pdf",
         invoiceDate: "2024-02-22",
         invoiceAmount: "$2200",
         totalConfirmed: "No",
         approval: "Pending",
         comments: "N/A",
         accComment: "Pending Review",
       },
     ],
   };

   useEffect(() => {
     if (formState.labId) {
       setInvoiceData(dummyInvoiceData[formState.labId] || []);
     }
   }, [formState.labId]);

   useEffect(() => {
      if (LabDetails?.length > 0 && !formState.labId) {
        const firstLab = LabDetails[0];
        setFormState({
          labName: firstLab.labName,
          labId: firstLab.labId,
        });
      }
   }, [LabDetails]);

  // useEffect(() => {
  //   if (formState.labId) {
  //     getInvoiceDetail(formState.labId);
  //   }
  // }, [formState.labId]);

  // // Function to fetch invoice details for the selected lab
  // const getInvoiceDetail = async (labId) => {
  //   try {
  //     const response = await AxiosInstance.get(`lab-partner/getLabInvoice?${labId}`);
  //     console.log('response: ', response);
  //     if (response != null) {
  //       setInvoiceData(response); // Set the invoice data for the selected lab
  //     } else {
  //       setInvoiceData([]);
  //     }
  //   } catch (err) {
  //     console.log("Error fetching invoices: ", err);
  //   }
  // };
    const handleSubmit = (invoiceDetails) => {
      // setTableData((prevData) =>
      //   prevData.map((row) =>
      //     row.orderId === selectedOrderId
      //       ? {
      //           ...row,
      //           invoiceDate: invoiceDetails.invoiceDate,
      //           invoiceAmount: invoiceDetails.invoiceAmount,
      //           invoiceFile: invoiceDetails.invoiceFile.name, // Assuming you want to show the file name
      //         }
      //       : row
      //   )
      // );
      setOpenModal(false);
      // getInvoiceDetail();
    };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "labName") {
      const selectedLab = LabDetails.find((lab) => lab.labName === value);
      setFormState({
        ...formState,
        labName: value,
        labContactNo: selectedLab ? selectedLab.labContact : "",
        labId: selectedLab ? selectedLab.labId : null,
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };
  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <h5>Select Lab Name : </h5>
        <hr style={{ border: "1px solid #000000", width: "12%" }}></hr>
        <Card className="LabOrderDetailCard">
          <Col md={6}>
            <FormGroup>
              <Label for="labName">
                <b>Lab Name:</b>
              </Label>
              <Input id="labName" name="labName" type="select" value={formState.labName} onChange={handleChange}>
                <option value="">Select Lab</option>
                {LabDetails?.map((lab) => (
                  <option key={lab.labId} value={lab.labName}>
                    {lab.labName}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Card>
        <br></br>
        {formState.labId && (
          <Card className="LabOrderDetailCard">
            <h3>Invoice</h3>
            <p style={{ marginTop: "1%" }}>
              <b>System will automatically generate the invoice information, and you will be able to attach your invoice for payment.</b>
            </p>
            <TableContainer>
              <Table style={{ border: "1px solid lightgrey", marginTop: "20px", whiteSpace: "nowrap" }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="tableCell">Invoice Month/Year</TableCell>
                    <TableCell className="tableCell">Total Orders</TableCell>
                    <TableCell className="tableCell">Total Amount</TableCell>
                    <TableCell className="tableCell">Invoice File</TableCell>
                    <TableCell className="tableCell">Invoice Date</TableCell>
                    <TableCell className="tableCell">Invoice Amount</TableCell>
                    <TableCell className="tableCell">Total Confirmed?</TableCell>
                    <TableCell className="tableCell">Invoice Approval</TableCell>
                    <TableCell className="tableCell">Comments</TableCell>
                    <TableCell className="tableCell">Account Mgr. Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ border: "1px solid black" }}>{row.invoiceMonthYear}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.totalOrders}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.totalAmount}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.invoiceFile}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.invoiceDate}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.invoiceAmount}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.totalConfirmed}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.approval}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>{row.comments}</TableCell>
                      <TableCell style={{ border: "1px solid black" }}>
                        {
                          <Button variant="contained" onClick={() => handleOpenCommentModal(row)}>
                            Add Comment
                          </Button>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}
      </Form>
      {selectedInvoice && (
        <AccountManagerCommentModel
          open={openCommentModal}
          onClose={handleCloseCommentModal}
          selectedInvoice={selectedInvoice} // Pass the selected invoice data
        />
      )}
    </>
  );
};
export default ApprovalsInvoice;
