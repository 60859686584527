import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography } from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";

const AccountManagerCommentModel = ({ open, onClose, onSubmit, orderId }) => {
  const [comment, setComment] = useState();
  const [approvalStatus, setApprovalStatus] = useState("");
  const [errors, setErrors] = useState({
    approvalStatus: false,
    comment: false,
  });

  const handleSubmit = async () => {
    let newErrors = {
      approvalStatus: !approvalStatus, // true if not selected
      comment: !comment,
    };
    setErrors(newErrors);
    if (!approvalStatus || !comment) {
      return; // Do not submit if there are validation errors
    }

    // try {
    //   await AxiosInstance.post(`lab-partner/invoice-upload/${orderId}`)
    //     .then((response) => {
    //       console.log("Upload successful:", response);
    //       toast.success("Invoice Details Submitted Successfully");
    //       onSubmit({ invoiceDate, comment, invoiceFile });
    //       onClose();
    //     })
    //     .catch((err) => {
    //       console.log("Upload error:", err);
    //     });
    // } catch (error) {
    //   console.error("Error uploading invoice:", error);
    // }
  };
  // Styles for the approval/decline divs
  const radioButtonStyle = (status) => {
    return approvalStatus === status
      ? {
          backgroundColor: status === "approve" ? "#c8e6c9" : "#EC9CA8FF",
          borderRadius: "4px",
        }
      : {};
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{ minHeight: "600px" }}>
      <DialogTitle>Add Account Manager's Comment</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" required>
          <FormLabel component="legend">Approval Status</FormLabel>
          <RadioGroup
            value={approvalStatus}
            onChange={(e) => {
              setApprovalStatus(e.target.value);
              setErrors((prevErrors) => ({
                ...prevErrors,
                approvalStatus: false,
              }));
            }}
            row
          >
            <div style={{ ...radioButtonStyle("approve"), position: "relative", border: "1px solid", borderRadius: "5px" }}>
              <FormControlLabel value="approve" control={<Radio style={{ position: "absolute", opacity: 0, pointerEvents: "none" }} />} label="Approve" style={{ marginLeft: "12px" }} />
            </div>
            <div style={{ ...radioButtonStyle("decline"), marginLeft: "16px", position: "relative", border: "1px solid", borderRadius: "5px" }}>
              <FormControlLabel value="decline" control={<Radio style={{ position: "absolute", opacity: 0, pointerEvents: "none" }} />} label="Decline" style={{ marginLeft: "12px" }} />
            </div>
          </RadioGroup>
          {errors.approvalStatus && <Typography color="error">Approval status is required</Typography>}
        </FormControl>
        <TextField
          fullWidth
          label="Comment"
          type="text"
          className="mt-5"
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
            setErrors((prevErrors) => ({
              ...prevErrors,
              comment: false,
            }));
          }}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          error={errors.comment}
          helperText={errors.comment ? "Comment is required" : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccountManagerCommentModel;
