import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";

const InvoiceModal = ({ open, onClose, onSubmit, orderId }) => {
  const [invoiceDate, setInvoiceDate] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState();
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [errors, setErrors] = useState({
    invoiceDate: false,
    invoiceAmount: false,
    invoiceFile: false,
  });

  const handleSubmit = async () => {
    let newErrors = {
      invoiceDate: false,
      invoiceAmount: false,
      invoiceFile: false,
    };

    if (!invoiceDate) {
      newErrors.invoiceDate = true;
    }
    if (!invoiceAmount) {
      newErrors.invoiceAmount = true;
    }
    if (!invoiceFile) {
      newErrors.invoiceFile = true;
    }

    setErrors(newErrors);

    if (invoiceDate && invoiceAmount && invoiceFile) {
      const formData = new FormData();
      formData.append("file", invoiceFile);
      formData.append("invoiceDate", invoiceDate);
      formData.append("invoiceAmount", invoiceAmount);

      try {
        await AxiosInstance.post(
          `lab-partner/invoice-upload/${orderId}`,
          formData
        )
          .then((response) => {
            console.log("Upload successful:", response);
            toast.success("Invoice Details Submitted Successfully");
            onSubmit({ invoiceDate, invoiceAmount, invoiceFile });
            onClose();
          })
          .catch((err) => {
            console.log("Upload error:", err);
          });
      } catch (error) {
        console.error("Error uploading invoice:", error);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Invoice Details</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Invoice Date"
          type="date"
          value={invoiceDate}
          onChange={(e) => {
            setInvoiceDate(e.target.value);
            setErrors((prevErrors) => ({
              ...prevErrors,
              invoiceDate: false,
            }));
          }}
          InputLabelProps={{ shrink: true }}
          margin="normal"
          error={errors.invoiceDate}
          helperText={errors.invoiceDate ? "Invoice Date is required" : ""}
        />
        <TextField
          fullWidth
          label="Invoice Amount"
          type="number"
          value={invoiceAmount}
          onChange={(e) => {
            setInvoiceAmount(e.target.value);
            setErrors((prevErrors) => ({
              ...prevErrors,
              invoiceAmount: false,
            }));
          }}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          error={errors.invoiceAmount}
          helperText={errors.invoiceAmount ? "Invoice Amount is required" : ""}
        />
        <Button
          variant="contained"
          component="label"
          fullWidth
          style={{ marginTop: "20px" }}
        >
          Attach Invoice File
          <input
            type="file"
            hidden
            onChange={(e) => {
              setInvoiceFile(e.target.files[0]);
              setErrors((prevErrors) => ({
                ...prevErrors,
                invoiceFile: false,
              }));
            }}
          />
        </Button>
        {errors.invoiceFile && (
          <Typography
            color="error"
            variant="body2"
            style={{ marginTop: "10px" }}
          >
            Invoice File is required
          </Typography>
        )}
        {invoiceFile && (
          <Typography variant="body2" style={{ marginTop: "10px" }}>
            Attached File: {invoiceFile.name}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceModal;
