import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import { IconMail } from "../../components/IconMail";
import { IconUser } from "../../components/IconUser";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { setCookies } from "../../configration/HandleCookies";
import IndiaFlag from "../../img/india-flag.png";
import ToastMessage from "../../shared/ToastMessage";
import "./style.css";

// Update: Function should accept props
export const MedicalLabSignUpModel = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setNumber] = useState("");
  const [firstNameerrorMessage, setFirstNameErrorMessage] = useState("");
  const [lastNameerrorMessage, setLastNameErrorMessage] = useState("");
  const [phoneNumbererrorMessage, setphoneNumberErrorMessage] = useState("");
  const [emailerrorMessage, setemailErrorMessage] = useState("");

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameErrorMessage("");
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameErrorMessage("");
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setemailErrorMessage("");
  };

  const onPhoneNumberChange = (e) => {
    const result = e.target.value.replace(/\D/g, "");
    setNumber(result);
    if (result) {
      setphoneNumberErrorMessage("");
    }
  };

  const getSubscriptions = async () => {
    return await AxiosInstance.get(`subscription/get-detail`)
      .then((response) => {
        return response.subscriptionStatus;
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const login = async () => {
    AxiosInstance.post("api/auth/signin", { email, password })
      .then(async (response) => {
        // setCookies(response);
        window.sessionStorage.setItem("token", response?.token);
        window.sessionStorage.setItem("userDetails", JSON.stringify(response));
        toast.success("Logged in successfully");
        let subscriptionResponse = await getSubscriptions();
        if (subscriptionResponse == "NO_SUBSCRIPTION" || subscriptionResponse == "EXPIRED_SUBSCRIPTION") {
          toast.success("You don't have an active subscription");
          // navigate(`/buy-subscription`);
          navigate(`/SubscriptionPageBeforeLogin`);
        } else {
          setTimeout(() => {
            navigate(`/home?isSignedUp=${true}`);
          }, 1000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const SignUp = async () => {
    if (firstName === "" || firstName == null || firstName == undefined) {
      setFirstNameErrorMessage("Please enter the First Name");
      return;
    }
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
    if (!regex.test(firstName)) {
      setFirstNameErrorMessage("Please enter a combination of alphabets and numbers.");
      return;
    }
    if (lastName === "" || lastName == null || lastName == undefined) {
      setLastNameErrorMessage("Please enter the Last Name");
      return;
    }
    const regex1 = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;

    if (!regex1.test(lastName)) {
      setLastNameErrorMessage("Please enter a combination of alphabets and numbers.");
      return;
    }
    if (phoneNumber === "" || phoneNumber == null || phoneNumber == undefined) {
      setphoneNumberErrorMessage("Please enter the phone number");
      return;
    }
    const regex2 = /^\d{10}$/;
    if (!regex2.test(phoneNumber)) {
      setphoneNumberErrorMessage("Phone number must be 10 digits long.");
      return;
    }
    if (email === "" || email == null || email == undefined) {
      setemailErrorMessage("Please enter the email");
      return;
    }

    await AxiosInstance.post("api/auth/signup", { email, firstName, lastName, phoneNumber })
      .then((response) => {
        const loginData = {
          username: email,
        };
        login(loginData);
        toast.success(response);
        onClose(); // Close modal after successful signup
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{ minHeight: "400px" }} className="sign-up">
      <DialogTitle>Medical Lab Partner's Sign Up</DialogTitle>
      <DialogContent>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <Row>
            <Col lg={6}>
              <div>
                <div className="text-wrapper-5">First Name</div>
                <div className="overlap-group-3">
                  <IconUser className="icon-user-instance" iconUser="/img/icon-user.png" />
                  <input type="text" onChange={onFirstNameChange} value={firstName} className="text-wrapper-7" placeholder="First Name" />
                </div>
              </div>
              {firstNameerrorMessage && <div className="error-message">{firstNameerrorMessage}</div>}
            </Col>
            <Col lg={6}>
              <div>
                <div className="text-wrapper-5">Last Name</div>
                <div className="overlap-group-3">
                  <IconUser className="icon-user-instance" iconUser="/img/icon-user.png" />
                  <input type="text" onChange={onLastNameChange} value={lastName} className="text-wrapper-7" placeholder="Last Name" />
                </div>
              </div>
              {lastNameerrorMessage && <div className="error-message">{lastNameerrorMessage}</div>}
            </Col>
          </Row>
        </div>
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <Row>
            <Col lg={6}>
              <div>
                <div className="text-wrapper-5">Phone Number</div>
                <div className="overlap-group-2">
                  <img className="icon-feather-phone" alt="Icon feather phone" src={IndiaFlag} />
                  <input type="tel" onChange={onPhoneNumberChange} value={phoneNumber} className="text-wrapper-7" placeholder="Phone Number" maxLength="10" />
                </div>
              </div>
              {phoneNumbererrorMessage && <div className="error-message">{phoneNumbererrorMessage}</div>}
            </Col>

            <Col lg={6}>
              <div>
                <div className="text-wrapper-5">Email</div>
                <div className="overlap-group-2">
                  <IconMail iconMail="/img/icon-mail.png" />
                  <input type="email" onChange={onEmailChange} value={email} className="text-wrapper-7" placeholder="Email" />
                </div>
              </div>
              {emailerrorMessage && <div className="error-message">{emailerrorMessage}</div>}
            </Col>
          </Row>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={SignUp} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
