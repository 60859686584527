import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Form, FormGroup, FormFeedback, FormText, Label, Input, Row, Col, Button } from "reactstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./style.css";
import InvoiceModal from "./InvoiceModal";
const Invoice = () => {
  const [rowData, setRowData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [tableData, setTableData] = useState(rowData); // Create a local state for the table data

  const handleOpenModal = (orderId) => {
    setSelectedOrderId(orderId);
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  const getInvoiceDetail = async() => {
    await AxiosInstance.get("lab-partner/getLabInvoice")
    .then((response) => {
      if (response != null) {
        setRowData(response);
      } else {
        setRowData([]);
      }
    })
    .catch((err) => {
      console.log('err: ', err);
    });
  }

  const handleSubmit = (invoiceDetails) => {
    // setTableData((prevData) =>
    //   prevData.map((row) =>
    //     row.orderId === selectedOrderId
    //       ? {
    //           ...row,
    //           invoiceDate: invoiceDetails.invoiceDate,
    //           invoiceAmount: invoiceDetails.invoiceAmount,
    //           invoiceFile: invoiceDetails.invoiceFile.name, // Assuming you want to show the file name
    //         }
    //       : row
    //   )
    // );
    setOpenModal(false);
    getInvoiceDetail()
  };
  
  useEffect(()=>{
    getInvoiceDetail();
  },[]);
    //  const rows = [
    //    {
    //      monthYear: "March 2024",
    //      totalOrder: "45",
    //      totalAmount: "9450",
    //      attachInvoice: "",
    //      invoiceDate: "2024-05-20",
    //      invoiceAmount: "9450",
    //      totalConfirmed: "Yes",
    //      invoiceApproval: "Approved",
    //      comments: "Invoice Ready to be Paid",
    //      accountMgrComment: "N/A",
    //      status: "To be paid"
    //    },
    //    {
    //      monthYear: "March 2024",
    //      totalOrder: "45",
    //      totalAmount: "9450",
    //      attachInvoice: "",
    //      invoiceDate: "2024-05-15",
    //      invoiceAmount: "9650",
    //      totalConfirmed: "No",
    //      invoiceApproval: "Not Approved",
    //      comments: "Please call our Account Manager to Resolve",
    //      accountMgrComment: "approved",
    //      status: "Paid Date"
    //    },
    //  ];
     console.log("inovoice" , rowData)
    return (
      <>
        <h3>Invoice</h3>
        <p style={{ marginTop: "1%" }}>
          <b>
            {/* An example will be shown below for invoice.  */}
            System will automatically generate the invoice information and you will be able to attach your invoice for payment.</b>
        </p>
        <TableContainer>
          <Table style={{ border: "1px solid lightgrey", marginTop: "20px" , whiteSpace : "nowrap"}}>
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">Invoice Month/Year</TableCell>
                <TableCell className="tableCell">Total Orders</TableCell>
                <TableCell className="tableCell">Total Amount</TableCell>
                <TableCell className="tableCell">Action</TableCell>
                <TableCell className="tableCell">Invoice File</TableCell>
                <TableCell className="tableCell">Invoice Date</TableCell>
                <TableCell className="tableCell">Invoice Amount</TableCell>
                <TableCell className="tableCell">Total Confirmed?</TableCell>
                <TableCell className="tableCell">Invoice Approval</TableCell>
                <TableCell className="tableCell">Comments</TableCell>
                <TableCell className="tableCell">Account Mgr. Comments</TableCell>
                <TableCell className="tableCell">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.orderDate}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.totalQuantity}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.totalAmount}</TableCell>
                  <TableCell style={{ border: "1px solid black" }}>
                   {row.totalConfirmed != "Yes" && <Button variant="contained" onClick={() => handleOpenModal(row.orderId)}>
                      Add Invoice
                    </Button>} 
                </TableCell>
                  <TableCell style={{ border: "1px solid black" }}>
                    {row.invoiceFile}
                  </TableCell>
                  <TableCell style={{ border: "1px solid black" }}>{row.invoiceDate}</TableCell>
                  <TableCell style={{ border: "1px solid black" }}>{row.invoiceAmount}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.totalConfirmed}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.approval}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.comments}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.accComment}</TableCell>
                  <TableCell style={{ border: "1px solid black", backgroundColor: "lightgray" }}>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedOrderId && (
        <InvoiceModal
          open={openModal}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          orderId={selectedOrderId}
        />
      )}
      </>
    );
};
export default Invoice;
