import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IconMail } from "../../components/IconMail";
import { IconSolidPassword } from "../../components/IconSolidPassword";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import ToastMessage from "../../shared/ToastMessage";
const GymLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceType, setDeviceType] = useState("Unknown");
  const [deviceLocation, setDeviceLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [partnerTypeId, setPartnerTypeId] = useState(null);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const getDeviceType = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.match(/Windows/i)) {
      return "Windows";
    } else if (userAgent.match(/Macintosh|Mac OS/i)) {
      return "Mac";
    } else {
      return "Unknown";
    }
  };

  const fetchCityName = async (latitude, longitude) => {
    const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
    const city = response.data.address.city || response.data.address.town || response.data.address.village;
    return city;
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    await AxiosInstance.post("api/partner-auth/signIn", {
      email,
      password,
      deviceName,
      deviceType,
      deviceLocation,
      partnerTypeId,
    })
      .then(async (response) => {
        console.log("response: ", response);
        // setCookies(response);
        window.sessionStorage.setItem("token", response?.token);
        window.sessionStorage.setItem("userDetails", JSON.stringify(response));
        toast.success("Logged in successfully");
        console.log("resss", response?.userType);
        navigate(`/gym-home-screen`);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/get-partner-type`)
      .then((response) => {
        console.log('responsegym: ', response);
        // Find the ID for the PHYSICIAN partner type and set it in state
        const physicianRole = response.data.data.find((role) => role.partnerType === "GYM");
        console.log('physicianRole: ', physicianRole);
        if (physicianRole) {
          setPartnerTypeId(physicianRole.id); // Set partnerTypeId to 5
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  return (
    <div className="login-email">
      <div className="overlapGym">
        <ToastMessage></ToastMessage>
        <div className="group-18">
          <img className="health-buddy" alt="Health buddy" src="/img/AppLogoWithName.png" />
        </div>
        <h3 style={{ marginLeft: "17%", marginTop: "5%", color:"#ffffff" }}>Welcome Gym Partners</h3>
        <div
          style={{
            display: "flex",
            marginLeft: "12%",
            padding: "30px 0px 10px 0px",
            marginTop: "-20px",
          }}
        >
          <div className="overlap-9">
            <div className="text-wrapper-19">Gym's Login</div>
            <div className="group-13">
              <div className="overlap-group-6">
                <div className="overlap-10">
                  <div className="text-wrapper-14">With Email</div>
                </div>
                <div style={{ display: "flex" }}>
                  <Link to="/login-otp" className="text-wrapper-15">
                    With OTP
                  </Link>{" "}
                </div>
              </div>
            </div>

            <form onSubmit={login}>
              <div className="group-17">
                <div className="text-wrapper-23">Email</div>
                <div className="group-15">
                  <div className="overlap-group-7">
                    <IconMail iconMail="/img/icon-mail.png" />
                    <input type="email" onChange={onEmailChange} className="emailinput" placeholder="Email" />
                  </div>
                </div>
              </div>
              <div className="group-14">
                <div className="text-wrapper-17">Password</div>
                <div className="group-15">
                  <div className="overlap-group-7">
                    <IconSolidPassword iconSolidPassword="/img/icon-solid-password.png" />
                    <input type={showPassword ? "text" : "password"} onChange={onPasswordChange} className="emailinput" placeholder="Enter Your password" />
                    {showPassword == false ? <VisibilityOffIcon onClick={togglePasswordVisibility} /> : <RemoveRedEyeIcon onClick={togglePasswordVisibility} />}
                  </div>
                </div>
              </div>
              <button className={`${email === "" ? "group-16disabled" : "group-16"}`} disabled={email === ""} type="submit">
                {loading && <CircularProgress />}
                {!loading && "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GymLogin;
