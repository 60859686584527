import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";
import InfoModel from "../../shared/simpleModel/InfoModel";
import { toast } from "react-toastify";

const DietIntervention = ({ selectedOrder, onSubmit }) => {
  const [askFurtherQuestions, setAskFurtherQuestions] = useState("");
  const [communication, setCommunication] = useState();
  const [questions, setQuestions] = useState([""]);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orderClosed, setOrderClosed] = useState(false);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);
  const [displayFileType, setDisplayFileType] = useState("");
  const handleRadioChange = (e) => {
    setAskFurtherQuestions(e.target.value);
    setQuestions([""]); // reset the question fields when option changes
    setErrors({});
  };

  const handleAddQuestion = () => {
    // if (questions.length < 3) {
    setQuestions([...questions, ""]);
    // }
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!askFurtherQuestions) {
      formErrors.askFurtherQuestions = "Please select an option";
    }
    if (askFurtherQuestions === "Yes") {
      questions.forEach((question, index) => {
        if (!question) {
          formErrors[`question${index}`] = "Question is required";
        }
      });
    }
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (askFurtherQuestions === "No") {
      AxiosInstance.post(`dietitian/order-accept/${selectedOrder.orderId}`)
        .then((response) => {
          setIsSubmitted(true);
          onSubmit();
          setOrderClosed(true);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    } else {
      if (validateForm()) {
        const userDetails = sessionStorage.getItem("userDetails");
        const parsedData = JSON.parse(userDetails);
        const userType = parsedData.userType;
        const formattedQuestions = questions.map((question) => ({
          question: question,
        }));
        console.log("form questions", questions, formattedQuestions);
        AxiosInstance.post(
          `dietitian/sendMassage/${selectedOrder.orderId}/${userType}`,
          formattedQuestions
        )
          .then((response) => {
            setIsSubmitted(true);
            onSubmit();
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    }
  };

  const downloadDietPlan = () => {
    console.log("url: ", communication?.dietReviewDto?.dietFile);
    const url = communication?.dietReviewDto?.dietFile;
    if (url === null) {
      return;
    }

    const downloadApi = "dietitian/downloadDietFile";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentType = response.headers.get("Content-Type");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts

              if (
                contentType === "application/pdf" ||
                contentType === "application/msword" ||
                contentType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // PDF/Word display in iframe
                setDisplayFileType("iframe");
              } else if (
                contentType ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                contentType === "application/vnd.ms-excel"
              ) {
                // Excel file handling
                setDisplayFileType("download");
              } else {
                // Handle other types as download
                setDisplayFileType("download");
              }
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  const closeReportModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (selectedOrder) {
      AxiosInstance.get(`dietitian/getCommunication/${selectedOrder.orderId}`)
        .then((response) => {
          if (response != null) {
            setCommunication(response);
          } else {
            setCommunication(null);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  }, []);
  console.log("communication", communication);
  return (
    <div>
      {communication ? (
        <>
          {isSubmitted ? (
            orderClosed ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    Order has been Successfully Closed.
                  </Typography>
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    You have successfully submitted your queries to the
                    Dietitian.
                  </Typography>
                  <div style={{ fontSize: "16px" }}>
                    You can expect a response from the Dietitian within 24-36
                    hours. Please check back later to see the response to your
                    queries.
                  </div>
                </div>
              </Box>
            )
          ) : (
            <>
              <Box>
                <Typography
                  component="div"
                  variant="h6"
                  sx={{ marginBottom: 1 }}
                >
                  Nutritionist Diet Plan
                </Typography>
                {communication?.dietReviewDto.review ? (
                  <TextField
                    // value={communication?.doctorReview.review}
                    value={communication?.dietReviewDto.review}
                    multiline
                    rows={4}
                    fullWidth
                    disabled
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-disabled": {
                        backgroundColor: "#d3d3d37a", // Full background color for the input
                      },
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black", // Ensures the text is black when disabled
                      },
                    }}
                  />
                ) : (
                  <div
                    style={{
                      fontWeight: "bolder",
                      fontSize: "20px",
                      color: "blue",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={downloadDietPlan}
                  >
                    View Diet Plan
                  </div>
                )}
              </Box>
              {communication.communicationView?.length > 0 && (
                <Box
                  sx={{
                    padding: "24px",
                    backgroundColor: "#1c1c1c", // Dark background
                    borderRadius: "16px", // Smoother rounded corners
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)", // Slightly darker shadow
                    border: "1px solid #333", // Darker border for structure
                    marginTop: "20px",
                  }}
                >
                  <h6 style={{ textAlign: "center", color: "white" }}>
                    Queries raised by patient
                  </h6>
                  {communication.communicationView?.map((item, index) => (
                    <Box key={item.id} sx={{ marginBottom: "24px" }}>
                      {/* Question Heading */}
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#ffa726", // Warm orange for headings
                          fontWeight: "bold",
                        }}
                      >
                        {`Question ${index + 1}`}
                      </Typography>
                      {/* Question Text */}
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontWeight: "bold",
                          color: "#ffffff", // White text for contrast
                          backgroundColor: "#424242", // Dark gray background for the question
                          padding: "12px",
                          borderRadius: "8px",
                          border: "1px solid #555", // Soft border for better separation
                        }}
                      >
                        {item.question}
                      </Typography>
                      {/* Doctor Response Heading */}
                      <Typography
                        variant="h6"
                        sx={{
                          marginTop: "8px",
                          color: "#80cbc4", // Soft teal for response heading
                          fontWeight: "bold",
                        }}
                      >
                        Doctor Response
                      </Typography>
                      {/* Doctor's Answer */}
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          color: "#e0f2f1", // Light teal for answer text
                          backgroundColor: "#333", // Darker background for the answer
                          padding: "12px",
                          borderRadius: "8px",
                          border: "1px solid #666", // Matching dark border
                        }}
                      >
                        {item.answer}
                      </Typography>
                      <Divider
                        sx={{ marginTop: "16px", borderColor: "#444" }}
                      />{" "}
                      {/* Subtle gray divider */}
                    </Box>
                  ))}
                </Box>
              )}
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    marginTop: 2,
                  }}
                >
                  <FormControl
                    component="fieldset"
                    required
                    error={!!errors.additionalTestsRequired}
                  >
                    <FormLabel component="legend">
                      Do you want to ask any further questions to the
                      Nutritionist regarding the Diet Plan and his or her review
                      comments ?
                    </FormLabel>
                    <RadioGroup
                      value={askFurtherQuestions}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {errors.askFurtherQuestions && (
                      <Typography color="error" variant="caption">
                        {errors.askFurtherQuestions}
                      </Typography>
                    )}
                  </FormControl>

                  {askFurtherQuestions === "Yes" &&
                    questions.map((question, index) => (
                      <TextField
                        key={index}
                        value={question}
                        onChange={(e) =>
                          handleQuestionChange(index, e.target.value)
                        }
                        placeholder={`Question ${index + 1}`}
                        required
                        fullWidth
                        sx={{ marginBottom: 1, marginRight: 1 }}
                        error={!!errors[`question${index}`]}
                        helperText={errors[`question${index}`]}
                      />
                    ))}

                  {askFurtherQuestions === "Yes" && (
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddQuestion}
                      >
                        Add More Question
                      </Button>
                    </Box>
                  )}
                  <Button type="submit" variant="contained" color="success">
                    Submit
                  </Button>
                </Box>
              </form>
            </>
          )}
        </>
      ) : (
        "No Diet Plan"
      )}

      <InfoModel
        open={modalIsOpen}
        setOpen={closeReportModal}
        maxWidth={"md"}
        contentLabel="File Popup"
        haveCloseIcon={true}
      >
        {reportUrl && displayFileType === "iframe" && (
          <iframe
            src={reportUrl}
            title="Report Viewer"
            style={{ width: "100%", height: "600px", border: "none" }}
          />
        )}
        {reportUrl && displayFileType === "download" && (
          <div>
            <p>File cannot be previewed. Click below to download:</p>
            <a href={reportUrl} download>
              Download File
            </a>
          </div>
        )}
      </InfoModel>
    </div>
  );
};

export default DietIntervention;
