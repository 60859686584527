import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import LifeStyleModificationRecommendation from "../LifeStyleRiskAssessment/components/LifeStyleModificationRecommendation";
import DieticianDiet from "./DieticianDiet";
import DieticianDietIntervention from "./DieticianDietIntervention";
import { useLocation } from "react-router-dom";

const DieticianOnlineConsultation = () => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [order, setOrder] = useState(location?.state?.order || {});

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    if (Object.keys(order).length === 0) {
      setOrder(location?.state?.order || {});
    }
  }, [location.state]);

  return (
    <>
      {order && Object.keys(order).length > 0 ? (
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            sx={{ backgroundColor: "steelblue" }}
          >
            <Tab
              label="Diagnosis"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                color: currentTabIndex === 0 ? "white" : "black",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
            <Tab
              label="Nutrition"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                color: currentTabIndex === 1 ? "white" : "black",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
            <Tab
              label="Diet Intervention"
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                color: currentTabIndex === 2 ? "white" : "black",
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
          </Tabs>
          <br />
          {currentTabIndex === 0 && (
            <Box sx={{ p: 3 }}>
              <LifeStyleModificationRecommendation></LifeStyleModificationRecommendation>
            </Box>
          )}
          {currentTabIndex === 1 && (
            <Box sx={{ p: 3 }}>
              <DieticianDiet order={order}></DieticianDiet>
            </Box>
          )}
          {currentTabIndex === 2 && (
            <Box sx={{ p: 3 }}>
              <DieticianDietIntervention
                order={order}
              ></DieticianDietIntervention>
            </Box>
          )}
        </Box>
      ) : (
        "No Patient for Online Consultation"
      )}
    </>
  );
};

export default DieticianOnlineConsultation;
