import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import NoOrderImage from "../LabScreens/Images/no-order.jpeg";
import Spinner from "../../shared/Spinner";
const PatientOrders = ({ onReviewClick }) => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchOrders = () => {
      const userDetails = sessionStorage.getItem("userDetails");
      if (!userDetails) return; // Handle missing userDetails

      const parsedData = JSON.parse(userDetails);
      const userType = parsedData?.userType;

      let doctorOrders = [];
      let dietitianOrders = [];

      setIsLoading(true);

      // Fetch doctor orders
      AxiosInstance.get(`doctor/orderDetails/${userType}`)
        .then((response) => {
          if (response) {
            doctorOrders = response;
          }
        })
        .catch((err) => {
          console.log("Error fetching doctor orders: ", err);
        })
        .finally(() => {
          // Fetch dietitian orders after doctor orders
          AxiosInstance.get(`dietitian/orderDetails/${userType}`)
            .then((response) => {
              if (response) {
                dietitianOrders = response;
              }
            })
            .catch((err) => {
              console.log("Error fetching dietitian orders: ", err);
            })
            .finally(() => {
              // Combine the two sets of orders
              const combinedOrders = [...doctorOrders, ...dietitianOrders];
              setOrders(combinedOrders);
              setIsLoading(false);
            });
        });
    };

    fetchOrders();
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        {/* <div
    style={{
      border: "1px solid lightgrey",
      padding: "15px",
      display: "flex",
      alignItems: "center",
      width: "25%",
    }}
  >
    <b>Date :</b>
    <span style={{ marginLeft: "10px" }}>
      <input
        type="date"
        className="form-control"
        id="dateInput"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </span>
  </div> */}
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you your orders from Uncurl: Health</b>
      </p>
      {isLoading ? (
        <Spinner />
      ) : orders?.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5", mr: 3 }}
            >
              QR = Questions Raised
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: "bold", color: "#3f51b5" }}
            >
              RR = Response Received
            </Typography>
          </Box>
          <TableContainer style={{ border: "1px solid black" }}>
            <Table>
              <TableRow>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>LSH Order ID </b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: " rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Date</b>
                  </p>
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>LRID</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Service Type</b>
                  </p>
                </TableCell>

                <TableCell
                  style={{
                    backgroundColor: "rgb(83, 83, 233)",
                    color: "white",
                    border: "1px solid lightgrey",
                    fontSize: "18px",
                  }}
                >
                  <p style={{ fontSize: "18px" }}>
                    <b>Status</b>
                  </p>
                </TableCell>
              </TableRow>
              <TableBody>
                {orders?.map((orders, index) => (
                  <TableRow
                    key={index}
                    // onClick={() => handleRowClick(orders, index)}
                    // style={{
                    //   cursor: "pointer",
                    //   backgroundColor:
                    //     clickedRowIndex === index ? "red" : "whitesmoke",
                    //   transition: "background-color 0.3s ease",
                    // }}
                    // onMouseEnter={(e) => {
                    //   if (clickedRowIndex !== index)
                    //     e.currentTarget.style.backgroundColor = "red";
                    // }}
                    // onMouseLeave={(e) => {
                    //   if (clickedRowIndex !== index)
                    //     e.currentTarget.style.backgroundColor = "whitesmoke";
                    // }}
                  >
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.createdOn ? formatDate(orders.createdOn) : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.preventiveTestReportView?.lrId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.serviceType}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {orders.statusForPatient === "Received" ||
                      orders.statusForPatient === "RR" ? (
                        <Button
                          variant="contained"
                          style={{
                            boxShadow: "0px 0px 10px 0px red",
                          }}
                          className="doctor-review-button"
                          onMouseEnter={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 20px 5px red";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.boxShadow =
                              "0px 0px 10px 0px red";
                          }}
                          onClick={() => onReviewClick(orders)}
                        >
                          {orders.statusForPatient}
                        </Button>
                      ) : (
                        orders.statusForPatient
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{ marginBottom: "2%", marginLeft: "17px" }}>
                No Orders Available!
              </h4>
              <img src={NoOrderImage} alt="Lab" style={{ width: "25%" }} />
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default PatientOrders;
