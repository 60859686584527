import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField } from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "./Admin.css";
import { toast } from "react-toastify";
import { MedicalLabSignUpModel } from "../SignUp/MedicalLabSignUpModel";
import { DoctorsSignUpModel } from "../SignUp/DoctorsSignUpModel";
import { CoperateSignUpModel } from "../SignUp/CoperateSignUpModel";
import { ResearchSignUpModel } from "../SignUp/ResearchSignUpModel";
import { DieticianSignUpModel } from "../SignUp/DieticianSignUpModel";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useNavigate } from "react-router";
const CreatePartners = () => {
   const [selectedRole, setSelectedRole] = useState("");
   const [openModel, setOpenModel] = useState(false);
   const [modelContent, setModelContent] = useState(null);
  const [partnersData, setPartnersData] = useState([]);
  
   const roleTitles = {
     medicalLab: "Medical Lab Partners Information",
     doctor: "Doctors Information",
     dieticians: "Dieticians Information",
     corporate: "Corporate Partners Information",
     research: "Research Partners Information",
   };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    setSelectedRole(selectedRole);

    // Set partners data based on the selected role
    setPartnersData(dummyPartnersData[selectedRole] || []);
  };

  // Open the modal based on the selected role
  const handleCreatePartnersClick = () => {
    switch (selectedRole) {
      case "medicalLab":
        setModelContent(<MedicalLabSignUpModel open={true} onClose={handleCloseModal} />);
        break;
      case "doctor":
        setModelContent(<DoctorsSignUpModel open={true} onClose={handleCloseModal} />);
        break;
      case "dieticians":
        setModelContent(<DieticianSignUpModel open={true} onClose={handleCloseModal} />);
        break;
      case "corporate":
        setModelContent(<CoperateSignUpModel open={true} onClose={handleCloseModal} />);
        break;
      case "research":
        setModelContent(<ResearchSignUpModel open={true} onClose={handleCloseModal} />);
        break;
      default:
        setModelContent(null);
    }
    setOpenModel(true); // Open the modal once the role is selected
  };
   const handleCloseModal = () => {
     setOpenModel(false);
       setModelContent(null);
   };

   const dummyPartnersData = {
     medicalLab: [
       {
         firstName: "John",
         lastName: "Doe",
         phoneNumber: "555-1234",
         email: "john.doe@example.com",
       },
       {
         firstName: "Jane",
         lastName: "Smith",
         phoneNumber: "555-5678",
         email: "jane.smith@example.com",
       },
     ],
     doctor: [
       {
         firstName: "Dr. Charlie",
         lastName: "Brown",
         phoneNumber: "555-8760",
         email: "charlie.brown@example.com",
       },
       {
         firstName: "Dr. Diana",
         lastName: "Green",
         phoneNumber: "555-1357",
         email: "diana.green@example.com",
       },
     ],
     dieticians: [
       {
         firstName: "Dietician Grace",
         lastName: "King",
         phoneNumber: "555-1472",
         email: "grace.king@example.com",
       },
       {
         firstName: "Dietician Helen",
         lastName: "Wong",
         phoneNumber: "555-2584",
         email: "helen.wong@example.com",
       },
     ],
     corporate: [
       {
         firstName: "Corporate CEO",
         lastName: "Sandra",
         phoneNumber: "555-1111",
         email: "sandra.ceo@corporate.com",
       },
       {
         firstName: "Corporate COO",
         lastName: "Michael",
         phoneNumber: "555-2222",
         email: "michael.coo@corporate.com",
       },
     ],
     research: [
       {
         firstName: "Researcher Dr. Leo",
         lastName: "Gomez",
         phoneNumber: "555-5000",
         email: "leo.gomez@research.com",
       },
       {
         firstName: "Researcher Dr. Maria",
         lastName: "Lopez",
         phoneNumber: "555-6000",
         email: "maria.lopez@research.com",
       },
     ],
   };

   return (
     <>
       <Form
         style={{
           border: "1px solid lightgrey",
           padding: "15px",
           marginTop: "25px",
           boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
           fontSize: "18px",
         }}
       >
         <h5>Select Partners: </h5>
         <hr style={{ border: "1px solid #000000", width: "12%" }} />
         <Card className="LabOrderDetailCard">
           <Col md={6}>
             <FormGroup>
               <Label for="roleSelect">
                 <b>Partners:</b>
               </Label>
               <select
                 className="custom-select"
                 id="roleSelect"
                 aria-label="Select Partner Role"
                 style={{
                   textAlign: "center",
                   width: "100%",
                 }}
                 onChange={handleRoleChange}
                 value={selectedRole}
               >
                 <option value="">Select Role</option>
                 <option value="medicalLab">Medical Lab</option>
                 <option value="doctor">Doctors</option>
                 <option value="dieticians">Dieticians</option>
                 <option value="corporate">Corporate</option>
                 <option value="research">Research</option>
               </select>
             </FormGroup>
           </Col>
         </Card>
         <br />
         {partnersData.length > 0 && (
           <Card className="LabOrderDetailCard">
             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
               <div>
                 <h5>{roleTitles[selectedRole]}</h5>
               </div>
               <Button variant="contained" color="primary" style={{ marginTop: "1%" }} onClick={handleCreatePartnersClick}>
                 Create Partners
               </Button>
             </div>
             <TableContainer>
               <Table style={{ border: "1px solid lightgrey", marginTop: "20px", whiteSpace: "nowrap" }}>
                 <TableHead>
                   <TableRow>
                     <TableCell className="tableCell">First Name</TableCell>
                     <TableCell className="tableCell">Last Name</TableCell>
                     <TableCell className="tableCell">Phone Number</TableCell>
                     <TableCell className="tableCell">Email</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {partnersData.map((partner, index) => (
                     <TableRow key={index}>
                       <TableCell style={{ border: "1px solid black" }}>{partner.firstName}</TableCell>
                       <TableCell style={{ border: "1px solid black" }}>{partner.lastName}</TableCell>
                       <TableCell style={{ border: "1px solid black" }}>{partner.phoneNumber}</TableCell>
                       <TableCell style={{ border: "1px solid black" }}>{partner.email}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
           </Card>
         )}
       </Form>
       {openModel && modelContent}
     </>
   );
};
export default CreatePartners;
