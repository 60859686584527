// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   Grid,
//   IconButton,
//   TextField,
// } from "@mui/material";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";

// const AppointmentScheduler = () => {
//   const today = dayjs();
//   const [selectedDate, setSelectedDate] = useState(today);
//   const [selectedTime, setSelectedTime] = useState("");

//   const timeSlotsSession1 = [
//     "08:30 am",
//     "08:45 am",
//     "09:00 am",
//     "09:15 am",
//     "09:30 am",
//     "09:45 am",
//     "10:00 am",
//     "10:15 am",
//     "10:30 am",
//     "10:45 am",
//     "11:00 am",
//     "11:15 am",
//     "11:30 am",
//     "11:45 am",
//     "12:00 pm",
//   ];
//   const timeSlotsSession2 = [
//     "02:00 pm",
//     "02:15 pm",
//     "02:30 pm",
//     "02:45 pm",
//     "03:00 pm",
//     "03:15 pm",
//     "03:30 pm",
//     "03:45 pm",
//     "04:00 pm",
//     "04:15 pm",
//     "04:30 pm",
//     "04:45 pm",
//     "05:00 pm",
//     "05:15 pm",
//     "05:30 pm",
//   ];

//   const handleDateChange = (direction) => {
//     if (direction === "next") {
//       setSelectedDate(selectedDate.add(1, "day"));
//     } else if (direction === "prev" && !selectedDate.isSame(today, "day")) {
//       setSelectedDate(selectedDate.subtract(1, "day"));
//     }
//   };

//   const handleTimeSelect = (time) => {
//     setSelectedTime(time);
//   };

//   return (
//     <Box p={3} display="flex" flexDirection="column" alignItems="center">
//       <Box
//         display="flex"
//         alignItems="center"
//         justifyContent="space-between"
//         mb={2}
//         width="100%"
//       >
//         <Typography variant="h6">Consultation Date</Typography>
//         <Box display="flex" alignItems="center">
//           <IconButton
//             onClick={() => handleDateChange("prev")}
//             disabled={selectedDate.isSame(today, "day")}
//           >
//             <ArrowBackIosIcon />
//           </IconButton>
//           <LocalizationProvider dateAdapter={AdapterDayjs}>
//             <DatePicker
//               value={selectedDate}
//               minDate={today}
//               onChange={(newDate) => setSelectedDate(newDate)}
//               format="DD/MM/YYYY"
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   variant="standard"
//                   inputProps={{
//                     ...params.inputProps,
//                     readOnly: true, // Make the text field read-only
//                   }}
//                 />
//               )}
//             />
//           </LocalizationProvider>
//           <IconButton onClick={() => handleDateChange("next")}>
//             <ArrowForwardIosIcon />
//           </IconButton>
//         </Box>
//       </Box>

//       <Typography variant="h6" color="textSecondary">
//         Session 1
//       </Typography>
//       <Grid container spacing={1} justifyContent="center" mb={3}>
//         {timeSlotsSession1.map((time) => (
//           <Grid item key={time}>
//             <Button
//               variant={selectedTime === time ? "contained" : "outlined"}
//               color={selectedTime === time ? "primary" : "default"}
//               onClick={() => handleTimeSelect(time)}
//             >
//               {time}
//             </Button>
//           </Grid>
//         ))}
//       </Grid>

//       <Typography variant="h6" color="textSecondary">
//         Session 2
//       </Typography>
//       <Grid container spacing={1} justifyContent="center" mb={3}>
//         {timeSlotsSession2.map((time) => (
//           <Grid item key={time}>
//             <Button
//               variant={selectedTime === time ? "contained" : "outlined"}
//               color={selectedTime === time ? "primary" : "default"}
//               onClick={() => handleTimeSelect(time)}
//             >
//               {time}
//             </Button>
//           </Grid>
//         ))}
//       </Grid>

//       {selectedTime && (
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => alert("Appointment booked!")}
//         >
//           Book Appointment
//         </Button>
//       )}
//     </Box>
//   );
// };

// export default AppointmentScheduler;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

const AppointmentScheduler = ({
  selectedDietician,
  onAppointmentSelect,
  type,
}) => {
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedTime, setSelectedTime] = useState("");
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [nextAvailableDate, setNextAvailableDate] = useState(
    today.add(1, "day")
  ); // Assume the next available date is tomorrow for simplicity.

  const timeSlotsSession1 = [
    "08:30 am",
    "08:45 am",
    "09:00 am",
    "09:15 am",
    "09:30 am",
    "09:45 am",
    "10:00 am",
    "10:15 am",
    "10:30 am",
    "10:45 am",
    "11:00 am",
    "11:15 am",
    "11:30 am",
    "11:45 am",
    "12:00 pm",
  ];
  const timeSlotsSession2 = [
    "02:00 pm",
    "02:15 pm",
    "02:30 pm",
    "02:45 pm",
    "03:00 pm",
    "03:15 pm",
    "03:30 pm",
    "03:45 pm",
    "04:00 pm",
    "04:15 pm",
    "04:30 pm",
    "04:45 pm",
    "05:00 pm",
    "05:15 pm",
    "05:30 pm",
  ];

  useEffect(() => {
    // Update current time every minute
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleDateChange = (direction) => {
    if (direction === "next") {
      setSelectedDate(selectedDate.add(1, "day"));
    } else if (direction === "prev" && !selectedDate.isSame(today, "day")) {
      setSelectedDate(selectedDate.subtract(1, "day"));
    }
  };

  const handleTimeSelect = (time) => {
    setSelectedTime(time);
  };

  // Filter available time slots based on selected date and current time
  const filterTimeSlots = (timeSlots) => {
    if (selectedDate.isSame(today, "day")) {
      const currentHour = currentTime.hour();
      const currentMinute = currentTime.minute();
      return timeSlots.filter((slot) => {
        const [hour, minute, period] = slot.split(/[: ]/);
        const slotHour =
          parseInt(hour) + (period === "pm" && hour !== "12" ? 12 : 0);
        const slotMinute = parseInt(minute);
        return (
          slotHour > currentHour ||
          (slotHour === currentHour && slotMinute > currentMinute)
        );
      });
    }
    return timeSlots;
  };

  const session1Slots = filterTimeSlots(timeSlotsSession1);
  const session2Slots = filterTimeSlots(timeSlotsSession2);
  const hasAvailableSessions =
    session1Slots.length > 0 || session2Slots.length > 0;

  console.log("selctesnutrition", selectedDietician);

  return (
    <Box p={3} display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mb={2}
        sx={{
          background:
            "#fff linear-gradient(259deg, #e2fdfc, #e1d9fc) 0 0 no-repeat padding-box",
          padding: "10px",
        }}
      >
        {/* Left Side: Dietician Name and Price */}
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {selectedDietician.firstName} {selectedDietician.lastName}
          </Typography>
          <Typography variant="subtitle1">
            ₹
            {type === "Audio"
              ? selectedDietician.audioCharge
              : selectedDietician.videoCharge}
          </Typography>
        </Box>

        {/* Right Side: Today's Date, Day, and Current Time */}
        <Box textAlign="right">
          <Typography variant="subtitle1" fontWeight="bold">
            {currentTime.format("ddd")}, {currentTime.format("DD/MM/YYYY")}
          </Typography>
          <Typography variant="subtitle1">
            <AccessTimeOutlinedIcon
              sx={{
                color: "blue",
                fontSize: "16px",
              }}
            />{" "}
            {currentTime.format("hh:mm A")}
          </Typography>
        </Box>
      </Box>

      {/* Date Navigation and Picker */}
      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <IconButton
          onClick={() => handleDateChange("prev")}
          disabled={selectedDate.isSame(today, "day")}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={selectedDate}
            minDate={today}
            onChange={(newDate) => setSelectedDate(newDate)}
            format="DD/MM/YYYY"
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
          />
        </LocalizationProvider>
        <IconButton onClick={() => handleDateChange("next")}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      {/* Time Slot Selection */}
      {/* <Typography variant="h6" color="textSecondary">
        Available Time Slots
      </Typography> */}
      {hasAvailableSessions ? (
        <>
          <Typography variant="body2" color="textSecondary">
            Session 1
          </Typography>
          <Grid container spacing={1} justifyContent="center" mb={3}>
            {session1Slots.map((time) => (
              <Grid item key={time}>
                <Button
                  variant={selectedTime === time ? "contained" : "outlined"}
                  color={selectedTime === time ? "primary" : "default"}
                  onClick={() => handleTimeSelect(time)}
                >
                  {time}
                </Button>
              </Grid>
            ))}
          </Grid>

          <Typography variant="body2" color="textSecondary">
            Session 2
          </Typography>
          <Grid container spacing={1} justifyContent="center" mb={3}>
            {session2Slots.map((time) => (
              <Grid item key={time}>
                <Button
                  variant={selectedTime === time ? "contained" : "outlined"}
                  color={selectedTime === time ? "primary" : "default"}
                  onClick={() => handleTimeSelect(time)}
                >
                  {time}
                </Button>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Box mt={3} textAlign="center">
          <Typography variant="h6" color="error" fontWeight="bold">
            No sessions available for {selectedDate.format("dddd, DD/MM/YYYY")}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Next Available Date: {nextAvailableDate.format("dddd, DD/MM/YYYY")}
          </Typography>
        </Box>
      )}

      {/* Book Appointment Button */}
      {selectedTime && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => onAppointmentSelect()}
        >
          Book Appointment
        </Button>
      )}
    </Box>
  );
};

export default AppointmentScheduler;
